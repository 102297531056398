.i{
    display: flex;
    height: 100vh;
}

.i-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-right {
    flex: 2;
    position: relative;
}

.heading-wrapper {
    z-index: 1;
    position: absolute;
    width: 72%;    
    top: 45%;
    left: 15%;

}

.heading {
    position: relative;
    font-size: 40px;
    z-index: 1;
    text-align: center;
    font-family: Lobster;
}

.i-left-wrapper{
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.i-intro {
    font-size: 40px;
    font-weight: 300;
}

.i-name {
    font-size: 50px;
}

.i-title {
    height: 50px;
    overflow: hidden;
}

.i-desc {
    font-size: 20px;
    font-weight: 300;
}

@keyframes move {
    16.65%{
        transform: translateY(-50px);
    }
    33.3%{
        transform: translateY(-100px);
    }
    49.95%{
        transform: translateY(-150px);
    }
    66.6%{
        transform: translateY(-200px);
    }
    83.25%{
        transform: translateY(-250px);
    }
    100%{
        transform: translateY(-300px);
    }
}

.i-title-wrapper {
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

.i-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: green;
    display: flex;
    align-items: center;
}

.i-scroll {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;

}

.i-img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    position: absolute;
    z-index: 0;
}

.i-bg {
clip-path: polygon(100% 0%, 100% 52%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: #34568B;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

a {
    text-decoration: none;
}

@media screen and (max-width: 1024px){
    .i-intro {
        font-size: 24px;
    }
    .i-desc {
        font-size: 18px;
    }
    .i-title {
        height: 45px;
        overflow: hidden;
    }
    .i-title-item {
        font-size: 25px;
    }

    .heading-wrapper {
        display: none;
    }
}

@media screen and (max-width: 905px){
    .i-title-item {
        font-size: 20px;
    }
}

@media screen and (max-width: 480px){
    .i {
        flex-direction: column;
    }

    .i-left-wrapper {
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%
    }
    .i-title {
        height: 50px;
    }

    .i-title-item {
        font-size: 30px;
    }

    .i-desc {
        display: none;
    }
}


@media screen and (max-height: 480px) {

    .i-scroll {
        display: none;
    }

    .i-desc {
        display: none;
    }
  }

  @media screen and (max-height: 359px) {
    .i-title {
        display: none;
    }
  }