.a {
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;

  }
  
  .a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }
  
  .a-right {
    flex: 1;
  }
  
  .a-card.bg {
    position: absolute;
    top: 80px;
    left: 150px;
  }
  
  .a-card {
    width: 50%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    transition-duration: 2s
  }

  .a-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .a-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .a-title {
    font-weight: 400;
  }
  
  .a-sub {
    margin: 20px 0px;
    font-size: 25px;
  }
  
  .a-desc{
      font-weight: 300;
      line-height: 30px;
      padding-right: 30px;
      font-size: 18px;
      text-align: justify;
      margin-right: 60px;
      width: 85%;
      justify-content: center;

  }

  *::before, *::after {
    box-sizing: border-box
  }

  .resume {
    cursor: pointer;
    position: relative;
    display: inline-block;
  }

  .resume:hover {
    color: blue;
  }

  .resume::before,
  .resume::after {
    --scale: 0;
    --arrow-size: 5px;
    --tooltip-color: blue;
    
    position: absolute;
    content: "";
    bottom: -.25rem;
    transform: translateX(0%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transform-origin: top center;
    transition:  150ms transform;
  }

  .resume::before {
    --translate-y: calc(100% + var(--arrow-size));

    content: attr(data-tooltip);
    font-size: 12px;
    color: white;
    padding: .2rem .5rem;
    border-radius: .3rem;
    text-align: center;
    background: var(--tooltip-color);
  }

  .resume:hover::before,
  .resume:hover::after {
    --scale: 1;
  }

  .resume::after {
    --translate-y: calc(1 * var(--arrow-size));
   
    content: "";
    border: var(--arrow-size) solid transparent;
    border-bottom-color: transparent;
    transform-origin: bottom center;
  }

  @media screen and (max-width: 1024px) {

    .a-card.bg {
      display: none;
    }

    .a-card.a-card.bg {
      top: 150px;
      left: 120px;
    }

    .a-card, .a-card.bg {
      height: 48%;
      width: 27vh;
    }

    .a-title {
      font-weight: 400;
      font-size: 30px;
    }
    .a-sub {
      font-size: 24px;
    }
    .a-desc {
      text-align: justify;
      font-size: 17px;
      width: 80%;
    }
  }

  @media screen and (max-width: 768px) {
    .a {
    flex-direction: column;
    text-align: center;
    padding-top: 65px;
    }

    .a-left {
      width: 80%;

    }

    .a-card {
      height: 40vh;
      border: white 10px solid;
    }

    .a-card.bg, .a-award  {
      display: none;
    }

    .a-right{
      padding: 40px 0px 80px 100px;
    }

    .a-desc {
      line-height: 22px;
      font-size: 11.5px;
      margin-left: 20px;
    }

    .a-title {
      margin-left: -45px;
    }

    .a-sub {
      margin-left: -45px;
    }
    
  }

  @media screen and (max-height: 550px) {

    .a {
      padding-bottom: 70px;
    }

    .a-right {
      margin-left: -20px;
    }

    .a-title {
      font-size: 15px;
      font-weight: 700;
      margin-top: -22px;
      margin-left: -80px;
      margin-bottom: 3px;
      margin-bottom: 20px;
    }

    .a-sub {
      display: none;
    }
  }

  @media screen and (max-width: 540px) {

    .a-title {
      margin-right: 80px;
    }

    .a-sub {
      font-size: 16px;
      margin-right: 80px;
    }

    .a-desc {
      font-size: 13px;
      width: 90%;
      margin-left: -30px;
    }
  }

  @media screen and (max-width: 480px) {
    .a {
    flex-direction: column;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 25px;
    }

    .a-left {
      width: 100%;

    }

    .a-card {
      height: 30vh;
      border: white 5px solid;
    }

    .a-card.bg, .a-award  {
      display: none;
    }

    .a-right{
      padding: 20px;
    }

    .a-desc {
      padding-left: 25px;
      font-size: x-small;
    }
  }

  @media screen and (max-width: 414px) {
    .a-card {
      width: 30vh;
    }
    .a-title {
      margin-top: -40px;
      margin-left: 90px;
    }

    .a-sub {
      margin-left: 90px;
      font-size: 15px;
    }
    .a-desc {
      margin-left: -1px;
      font-size: 12px;
      width: 85%;
      line-height: 25px;
    }
  }

  @media screen and (max-width: 411px) {
    .a-title {
      margin-left: 80px;
    }

    .a-sub {
      font-size: 15px;
      margin-left: 80px;
    }

    .a-desc {
      font-size: 13px;
      width: 83%;
      line-height: 22px;
      margin: 4px;
    }
  }

  @media screen and (max-width: 375px) {
    .a-title {
      margin-left: 80px;
      margin-bottom: 15px;
    }
    .a-desc {
      margin-left: 2px;
      font-size: 12px;
      width: 85%;
      line-height: 22px;
    }

    .resume::before {
      font-size: 7px;
    }
  }

  @media screen and (max-width: 360px) {
    .a-desc {
      font-size: 11.5px;
      margin-left: -1px;
      width: 80%;
    }
    .a-title {
      font-size: 20px;
    }
    .a-right {
      width: 80%;
    }
  }

  @media screen and (max-width: 320px) {
    .a-desc {
      width: 80%;
      font-size: 10px;
      line-height: 19px;
    }

    .a-title {
      font-size: 13px;
    padding-bottom: 5px;
    }

    .a-sub {
      font-size: 11px;
        }
  }

  @media screen and (max-width: 280px) {
    .a-desc {
      font-size: 13px;
      width: 80%;
      text-align: justify;
      margin-left: -3px;
    }

    .a-title {
      font-size: 13px;
    padding-bottom: 5px;
    }

    .a-sub {
      display: none;
        }
  }