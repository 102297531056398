@import "../../global.scss";

.portfolio {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 150px 0px;

    h1 {
        font-size: 80px;

        @include mobile {
            margin-top: 40px;
            font-size: 20px;
        }
    }

    p {
        margin: 20px 0px;
        width: 65%;
        padding-bottom: 5px;
        text-align: justify;
        font-weight: 300;
        line-height: 30px;
        font-size: 18px;        

        @include mobile {
            font-size: 11.5px;
            width: 75%;
            line-height: 20px;
        }
    }

    ul {
        margin: 10px;
        padding: 10px;
        list-style: none;
        display: flex;

        @include mobile {
            margin-left: 50px;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .container {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile {
            width: 100%;
        }

        .item {
            width: 220px;
            height: 168px;
            border-radius: 20px;
            border: 1px solid #240, 239, 239;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all .5s ease;
            cursor: pointer;

            @include mobile {
                width: 130px;;
                height: 100px;
            }

            h3 {
                position: absolute;
                font-size: 20px;
                text-align: center;

                @include mobile {
                    font-size: 12px;
                    text-align: center;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
                border-radius: 20px;
            }

            &:hover {
                background-color: $mainColor;
                img {
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }
}

