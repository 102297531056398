.c {
    height: 77vh;
    position: relative;
    padding-bottom: 20px;
  }
  
  .c-bg {
    width: 20px;
    height: 100%;
    background-color: #34568B;
    position: absolute;
  }
  
  .c-wrapper {
    padding: 50px;
    display: flex;
  }
  
  .c-left {
    flex: 1;
  }
  
  .c-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .c-title {
    font-size: 60px;
    width: 80%;
  }
  
  .c-info-item {
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 300;
    width: 70%;
  }
  
  .c-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
  
  .c-desc {
    font-weight: 300;
  }
  
  form {
    margin-top: 20px;
  }
  
  input {
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
  }
  
  textarea {
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
  }
  
  button {
    border: none;
    padding: 15px;
    background-color: #34568B;
    color: white;
    font-weight: 500;
    cursor: pointer;
  }

  @media screen and (max-width: 1280px){
    .c {
      height: 73vh;
    }
  }

  .c-info-item {
    margin: 30px 0px;
  }

  @media screen and (max-width: 1024px){
    .c {
      height: 45vh;
      padding-bottom: 295px;
    }
    .c-desc {
      margin-bottom: -32px;
      font-style: 20px;
      padding-bottom: 20px;
    }
  }

  @media screen and (max-width: 768px){
    .c {
      font-size: 18px;
      padding-bottom: 50px;
    }
    .c-info-item {
      padding: 10px 0px;
    }
  }

  @media screen and (max-width: 540px){
    .c-desc {
      font-size: 40px;
    }
    .c-desc {
      font-size: 14px;
    }
    .c {
      height: 100vh;
    }
  }

 @media screen and (max-width: 480px){
    .c-wrapper {
        flex-direction: column;
        padding: 0px 50px;
    }

    .c-title {
      font-size: 30px;
    }

    .c-info-item {
      margin: 20px 0px;
      width: 100%;
    }

    .c-desc {
      display: none;
    }

    form {
      margin-top: 0px;
      display: flex;
      flex-wrap: wrap;
    }

    input {
      width: 35%;
      height: 40px;
      margin: 10px;
      margin-left: 0;
    }

    button {
      margin-top: 10px;
    }
}

@media screen and (max-width: 414px){
  .c {
    height: 72vh;
    font-size: 12px;
    }
  }

  @media screen and (max-width: 411px){
    .c {
      height: 72vh;
      font-size: 12px;
      }
    }
  

  @media screen and (max-width: 375px){
    .c-wrapper {
      padding-top: 30px 0;
  }
  }

@media screen and (max-width: 360px){
  .c {
    height: 82vh;
    }

  .c-wrapper {
      flex-direction: column;
      padding: 0px 50px;
      font-size: 12px;
  }

  .c-title {
    font-size: 30px;
  }

  .c-info-item {
    margin: 20px 0px;
    width: 100%;
  }

  .c-desc {
    display: none;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 35%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 320px){
  .c {
    height: 92vh;  
  }
}

@media screen and (max-height: 1393px){
  .c {
    height: 20vh;  
    padding-bottom: 300px;
  }
}

@media screen and (max-height: 1366px){
  .c {
    height: 20vh;  
    padding-bottom: 300px;
  }
}

@media screen and (max-height: 1365px){
  .c {
    height: 20vh;  
    padding-bottom: 10px;
  }
}


@media screen and (max-height: 1100px){
  .c {
    height: 39vh;  
    padding-bottom: 100px;
  }
}

@media screen and (max-height: 1094px){
  .c {
    height: 39vh;  
    padding-bottom: 200px;
  }
}

@media screen and (max-height: 1024px){
  .c {
    height: 39vh;  
    padding-bottom: 300px;
  }
}

@media screen and (max-height: 840px){
  .c {
    height: 63vh;  
  }
  .c-left {
    padding-top: 20px;
  }
}

@media screen and (max-height: 800px){
  .c {
    height: 40vh;  
  }
}

@media screen and (max-height: 736px){
  .c {
    height: 70vh;  
    padding-bottom: 300px;
  }
}

@media screen and (max-height: 731px){
  .c {
    height: 70vh;  
    padding-bottom: 300px;
  }
  .c-left {
    padding-top: 20px;
  }
}

@media screen and (max-height: 720px){
  .c {
    height: 55vh;  
  }
}


@media screen and (max-height: 670px){
  .c {
    padding-bottom: 540px;
  }
}


@media screen and (max-height: 667px){
  .c {
    height: 37vh;  
    padding-bottom: 550px;
  }
}

@media screen and (max-height: 640px){
  .c {
    height: 42vh;  
    padding-bottom: 520px;
  }
  .c-left {
    padding-top: 20px;
  }
}

@media screen and (max-height: 600px){
  .c {
    height: 8vh;  
    padding-bottom: 550px;
  }
}

@media screen and (max-height: 568px){
  .c {
    padding-bottom: 750px;
  }
}