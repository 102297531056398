@import "../../global.scss";

.portfolioList {
    font-style: 14px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;


    @include mobile {
        margin-right: 20px;
        font-size: 13px;
        text-align: center;
    }

    &.active {
        background-color: #34568B;
        color: white;
    }
}
